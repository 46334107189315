<template>
  <div class="container-login">
    <form action="">
      <a href="https://cams.clubcamgirl.com"><img class="logoCamLogin m-b-10" src="/img/camclub.png" alt="" /></a>
      <h1 class="mq-sz">Login</h1>
      <input
        class="m-b-2"
        id="email"
        type="e-mail"
        placeholder="Email"
        v-model="email"
      />
      <div class="svg-input m-b-3">
        <input
          class="svginput"
          id="password"
          v-bind:type="typefield"
          placeholder="**********"
          v-model="password"
        />
        <eye v-if="!shut" v-on:click.native="changeType()"></eye>
        <eyeShut v-else v-on:click.native="changeType()"></eyeShut>
      </div>
      <button v-if="!logginLoad" class="btn-login" v-on:click.stop.prevent="Login">Log In</button>
      <button v-else class="btn-login" disabled>
        <div class="lds-ring"><div></div><div></div><div></div><div></div></div>
      </button>
      <div class="login-links">
        <!-- <router-link to="/forgotpass"
          ><a href="/forgotPassword">Forgot your password?</a></router-link
        > -->
        <router-link to="/register"
          ><a><span>Don't Have an Account?</span></a></router-link
        >
      </div>
    </form>
  </div>
</template>

<script>
import Toastify from "toastify-js";
import eye from "../components/littles/eye.vue";
import EyeShut from '../components/littles/eyeShut.vue';
export default {
  metaInfo() {
    return {
      title: "Login now!",
      titleTemplate: "%s - Club Cam Girls!",
      htmlAttrs: {
        lang: "en",
        amp: true,
      },
      link: [{ rel: "icon", href: "/favicon.png" }],
    };
  },
  data() {
    return {
      email: null,
      password: null,
      typefield: "password",
      shut: false,
      logginLoad: false,
    };
  },

  components: {
    eye,
    EyeShut,
  },

  mounted() {
    this.LogoutOnAccess();
  },

  methods: {
    changeType() {
      if (this.typefield === "password") {
        this.shut = true;
        this.typefield = "text";
        return;
      }
      this.typefield = "password";
      this.shut = false;
    },
    LogoutOnAccess() {
      this.$cookies.remove("_user_base");
      this.$cookies.remove("_session_user");
    },
    async Login() {
      
      if (!this.email) {
        Toastify({
          text: "What's your email",
          duration: 3000,
          destination: "https://github.com/apvarun/toastify-js",
          newWindow: true,
          close: true,
          gravity: "top", // `top` or `bottom`
          position: "left", // `left`, `center` or `right`
          backgroundColor: "linear-gradient(to right, #00b09b, #96c93d)",
          stopOnFocus: true, // Prevents dismissing of toast on hover
          onClick: function () {}, // Callback after click
        }).showToast();
        return;
      }
      if (!this.password) {
        Toastify({
          text: "Password?",
          duration: 3000,
          destination: "https://github.com/apvarun/toastify-js",
          newWindow: true,
          close: true,
          gravity: "top", // `top` or `bottom`
          position: "left", // `left`, `center` or `right`
          backgroundColor: "linear-gradient(to right, #00b09b, #96c93d)",
          stopOnFocus: true, // Prevents dismissing of toast on hover
          onClick: function () {}, // Callback after click
        }).showToast();
        return;
      }
      this.logginLoad = true;
      const model = await this.axios({
        method: "POST",
        baseURL: process.env.VUE_APP_BASE_URL,
        url: `/api/v1/models/login`,
        data: {
          email: this.email,
          password: this.password,
        },
      });

      console.log(model);

      if (model.data.status === 404) {
        this.logginLoad = false;
        Toastify({
          text: "This email doesn't exist 📧",
          duration: 3000,
          destination: "https://github.com/apvarun/toastify-js",
          newWindow: true,
          close: true,
          gravity: "top", // `top` or `bottom`
          position: "left", // `left`, `center` or `right`
          backgroundColor: "linear-gradient(to right, #00b09b, #96c93d)",
          stopOnFocus: true, // Prevents dismissing of toast on hover
          onClick: function () {}, // Callback after click
        }).showToast();
        return;
      }

      if (model.data.status === 400) {
        this.logginLoad = false;
        Toastify({
          text: "Password wrong 🙈",
          duration: 3000,
          destination: "https://github.com/apvarun/toastify-js",
          newWindow: true,
          close: true,
          gravity: "top", // `top` or `bottom`
          position: "left", // `left`, `center` or `right`
          backgroundColor: "linear-gradient(to right, #00b09b, #96c93d)",
          stopOnFocus: true, // Prevents dismissing of toast on hover
          onClick: function () {}, // Callback after click
        }).showToast();
        return;
      }

      this.$cookies.set("_user_base", model.data.token, "24H");
      this.$router.push("/profile/user");
    },
  },
};
</script>